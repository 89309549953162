@import "reset-css";

// fonts
@font-face {
  font-family: "Proxima";
  src: url("assets/fonts/proxima/ProximaNova-Regular.otf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Proxima";
  src: url("assets/fonts/proxima/ProximaNova-Bold.otf");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/Inter-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/Inter-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

//  -----Default Styles-----
$primary-color: #121316;
$secondary-color: #787883;
$title-color: #121316;
$subtitle-color: #787883;
$text-color: #363740;

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: "Proxima", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: $primary-color;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 1.6rem;
}

input {
  background: none;
  border: none;
  outline: none !important;
}
.input-error {
  border-color: #a31621 !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

button {
  width: 100%;
  height: 4.6rem;
  padding: 0;
  font-family: "Proxima", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.015em;
  color: $primary-color;
  background: none;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}

.title {
  margin-bottom: 0.4rem;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.015em;
  color: $title-color;
}
.subtitle {
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: $subtitle-color;
}

.primary-logo-wrap {
  margin: 4rem 0 2.4rem;
}
.secondary-logo-wrap {
  margin-bottom: 3.2rem;
  display: flex;
  justify-content: center;
}

.follow-link {
  display: inline-block;
  padding: 1rem 2.25rem;
  border: 1px solid #363740;
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #363740;
}

.MuiAutocomplete-option {
  font-size: 1.4rem;
}
